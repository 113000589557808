<template>
  <div class="vp-flex vp-flex-wrap vp-gap-3">
    <button
      v-for="option in options"
      :key="option.value"
      @click="$emit('input', option.value)"
      class="vp-flex vp-items-center vp-gap-2 vp-px-4 vp-py-2 vp-rounded-full vp-border vp-bg-gray-50 vp-transition-all"
      :class="[
        value == option.value
          ? 'vp-border-primary-500 vp-bg-primary-50 vp-shadow-sm'
          : 'vp-border-gray-200 hover:vp-border-gray-300',
      ]"
    >
      <span v-if="option.icon" class="vp-text-xl">{{ option.icon }}</span>
      <span class="vp-font-medium vp-text-gray-900">{{ option.label }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "SelectGrid",
  props: {
    value: null,
    options: {
      type: Array,
      required: true,
      validator: (options) =>
        options.every((option) => "value" in option && "label" in option),
    },
  },
};
</script>
