import Onboard from "@/views/onboard/questions/index.vue";
import OnboardStep from "components/src/onboard/step.vue";

export default [
  {
    path: "/onboard",
    component: Onboard,

    children: [
      {
        path: "", // Redirect to first step if accessing `/onboard`
        name: "onboard-questions",
        redirect: { name: "onboard-step", params: { step: 1 } },
      },
      {
        path: "step/:step",
        component: OnboardStep,
        name: "onboard-step",
      },
    ],
  },
];
