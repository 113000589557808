<template>
  <div class="vp-h-full vp-w-full">
    <AppLayout>
      <Onboard product="store" />
    </AppLayout>
  </div>
</template>

<script>
import AppLayout from "@/components/app-layout.vue";
import Onboard from "components/src/onboard/index.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    AppLayout,
    Onboard,
  },
  computed: {
    ...mapGetters({
      isStoreProductSetup: "biz/isStoreProductSetup",
    }),
  },
};
</script>
