<template>
  <div class="vp-h-full vp-w-full">
    <Loader v-if="isLoading" :show-logo="false" />
    <div v-else class="vp-p-4 vp-flex vp-w-[678px] vp-mx-auto vp-h-full">
      <div class="vp-w-full">
        <div class="vp-w-full vp-text-center">
          <ProgressIndicator :totalSteps="onboardQuestionsStepCount" />
        </div>
        <div class="vp-mt-24">
          <VyButton
            v-if="currentStep > 1"
            label="Back"
            :to="{ name: 'onboard-step', params: { step: currentStep - 1 } }"
            class="button--primary button--md vp-p-0 vp-cursor-pointer"
            :icon="$options.icons.ArrowLeft"
          />

          <h2 class="vp-my-4 vp-text-3xl vp-font-bold vp-max-w-[350px]">
            {{ currentStepOnboardQuestion.name }}
          </h2>

          <RouterView
            :current-form-state="currentFormState"
            :current-step-onboard-question="currentStepOnboardQuestion"
            @onSelect="onSelect"
            @next="next"
            :key="`onboard-step-${currentStep}`"
          >
          </RouterView>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressIndicator from "./progress-indicator.vue";
import get from "graph/onboard/questions.gql";
import Loader from "../app/loader.vue";
import save from "graph/onboard/save.gql";
import { ArrowLeft } from "icons/icons.js";
export default {
  icons: {
    ArrowLeft,
  },
  components: {
    ProgressIndicator,
    Loader,
  },
  props: {
    product: String,
  },
  created() {
    if (this.currentStep > 1)
      this.$router.push({ name: "onboard-step", params: { step: 1 } });

    this.getQuestions();
  },

  data() {
    return {
      formState: [],
      isLoading: false,
      isSaving: false,
      selectedTeamSize: null,
      onboardQuestions: [],
    };
  },
  computed: {
    onboardQuestionsStepCount() {
      return this.onboardQuestions.length;
    },
    currentStep() {
      return parseInt(this.$route.params.step) || 1;
    },
    currentStepOnboardQuestion() {
      return this.onboardQuestions[this.currentStep - 1];
    },

    currentFormState() {
      return this.formState.find(
        (form) => form.formId == this.currentStepOnboardQuestion.id
      );
    },
    isLastStep() {
      return parseInt(this.$route.params.step) == this.onboardQuestionsStepCount
        ? true
        : false;
    },
  },

  methods: {
    onSelect(value) {
      const selection = this.formState.find((form) => {
        return form.formId === this.currentStepOnboardQuestion.id;
      });

      selection.value = value;

      this.next();
    },
    next() {
      if (this.isLastStep) {
        this.saveOnboarding();
      } else {
        setTimeout(() => {
          // UX update for user to see what is selected instead of instant next route navigate
          this.$router.push({
            name: "onboard-step",
            params: { step: this.currentStep + 1 },
          });
        }, 500);
      }
    },
    getQuestions() {
      this.isLoading = true;
      return this.$query(get, {
        product: this.product,
      })
        .then(({ data }) => {
          this.onboardQuestions = data.forms.data.map((form) => {
            let _fields = form.fields.map((field) => {
              return {
                label: field.text,
                value: field.id,
              };
            });

            return {
              ...form,
              fields: _fields,
            };
          });

          this.onboardQuestions.forEach((form) => {
            this.formState.push({
              formId: form.id,
              value: null,
            });
          });
        })
        .catch()
        .finally(() => {
          this.isLoading = false;
        });
    },

    saveOnboarding() {
      this.$mutate(save, {
        input: this.formState,
      })
        .then(() => {
          this.$router.push({ name: "home" });
        })
        .catch((error) => {
          console.error(error);
          this.$vayu.notify({
            message: "Something went wrong. Try again.",
            state: "danger",
          });
        });
    },
  },
};
</script>
