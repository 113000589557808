<template>
  <div class="vp-flex vp-items-center vp-gap-2 vp-justify-center vp-my-4">
    <div
      v-for="step in totalSteps"
      :key="step"
      :class="[
        'vp-w-12 vp-h-2 vp-rounded vp-transition-colors vp-duration-300',
        step <= currentStep ? 'vp-bg-primary-500' : 'vp-bg-gray-300',
      ]"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    totalSteps: {
      type: Number,
      required: true,
    },
  },
  computed: {
    currentStep() {
      return parseInt(this.$route.params.step) || 1;
    },
  },
};
</script>
