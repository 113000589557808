<template>
  <Loader v-if="loading" />

  <!-- Hydrated -->

  <AppLayout v-else>
    <main class="vp-overflow-auto">
      <div class="vp-flex vp-h-full vp-flex-col">
        <EmailNotVerified />
        <!-- TODO remove alert banner after 1 month of deployment live -->
        <AlertBanner
          title="Introducing Catalog"
          desc="Manage Products, Categories and Inventory now from catalog."
        >
          <template #action>
            <VyButton
              :href="$options.VITE_CATALOG_URL"
              label="Open Catalog"
              class="button--warning button--muted button--md button--rounded"
            />
          </template>
        </AlertBanner>
        <AccountDowngraded />
        <!-- Header -->
        <div
          class="vp-h-12 vp-flex vp-items-stretch vp-border-b vp-relative vp-z-20"
        >
          <SwitchBiz />
          <Breadcrumbs class="vp-flex-grow" />
          <div class="vp-ml-auto vp-flex vp-items-center vp-px-2">
            <PortalTarget name="header" slim />
            <TheNotification
              :is-notification="unreadNotificationCount ? true : false"
            />
            <VyButton
              v-tooltip="'Visit Store'"
              :icon="$options.icons.OpenInNew"
              class="button--gray button--rounded button--md -vp-mx-2"
              :href="bizUrl"
              target="_blank"
            />
          </div>
        </div>
        <!-- Content -->

        <!-- Handle Scroll issue -->
        <div class="vp-flex-grow vp-overflow-auto">
          <VpSubShell>
            <template #nav>
              <VpSubNavItem
                label="Dashboard"
                :icon="$options.icons.Dashboard"
                :to="{ name: 'home' }"
                exact
              />

              <VpSubNavItem
                label="Orders"
                :icon="$options.icons.Cart"
                :to="{ name: 'orders' }"
              />

              <VpSubNavItem
                label="Contacts"
                :icon="$options.icons.ContactBook"
                :to="{ name: 'contacts' }"
              />

              <VpSubNavItem
                label="Reviews"
                :icon="$options.icons.Rating"
                :to="{ name: 'product-reviews' }"
              />

              <VpSubNavItem
                label="Coupons"
                :icon="$options.icons.Coupon"
                :to="{ name: 'coupons' }"
              />

              <!-- PRODUCT -->

              <VpNavGroup>Customization</VpNavGroup>

              <VpSubNavItem
                label="About"
                :icon="$options.icons.About"
                :to="{ name: 'about' }"
              />

              <VpSubNavItem
                label="Banners"
                :icon="$options.icons.Banners"
                :to="{ name: 'banners' }"
              />
              <VpSubNavItem
                label="Menus"
                :icon="$options.icons.Menu"
                :to="{ name: 'menus' }"
              />

              <VpSubNavItem
                label="Contact Numbers"
                :icon="$options.icons.Whatsapp"
                :to="{ name: 'numbers' }"
              />

              <VpSubNavItem
                label="Links"
                :icon="$options.icons.Link"
                :to="{ name: 'links' }"
              />
              <VpSubNavItem
                label="Social Links"
                :icon="$options.icons.Twitter"
                :to="{ name: 'social-links' }"
              />

              <VpSubNavItem
                label="Pages"
                :icon="$options.icons.Document"
                :to="{ name: 'pages' }"
              />
              <VpSubNavItem
                label="Theme"
                :icon="$options.icons.Theme"
                :to="{ name: 'theme' }"
              />

              <VpNavGroup>More</VpNavGroup>
              <VpSubNavItem
                label="Settings"
                :icon="$options.icons.Settings"
                class="settings"
                :to="{ name: 'settings' }"
              />
              <VpSubNavItem
                label="Export History"
                exact
                :icon="$options.icons.History"
                :to="{ name: 'export-history' }"
              />
            </template>
          </VpSubShell>
        </div>
      </div>
      <FrillWidget />
    </main>
  </AppLayout>
</template>

<script>
import { version } from "../../../package.json";
import EmailNotVerified from "components/src/email/not-verified.vue";
import hydrate from "./get.gql";
import { getCampaign, setCampaign } from "plugins/utils.js";
import TheNotification from "components/src/notifications/header-button.vue";
import SwitchBiz from "components/src/biz-display.vue";
import AlertBanner from "./_alert-banner.vue";
import FrillWidget from "components/src/frill-widget.vue";
const { VITE_CATALOG_URL } = import.meta.env;

import {
  Menu,
  Coupon,
  AlertMessage,
  Twitter,
  Banners,
  About,
  Cart,
  ContactBook,
  Document,
  Checkout,
  Whatsapp,
  Dashboard,
  OpenInNew,
  Payment,
  Rating,
  Group,
  Settings,
  Sitemap,
  Theme,
  Link,
  Notification,
  History,
} from "icons/icons.js";
import AccountDowngraded from "components/src/account/downgraded.vue";
import Breadcrumbs from "components/src/app/breadcrumbs.vue";
import { mapActions, mapGetters } from "vuex";
import Loader from "components/src/app/loader.vue";
import unreadNotification from "components/src/notifications/unread-count.gql";
import { toWeb } from "plugins/utils.js";
import AppLayout from "@/components/app-layout.vue";

export default {
  version,
  VITE_CATALOG_URL,

  icons: {
    History,
    Coupon,
    Menu,
    About,
    AlertMessage,
    ContactBook,
    Theme,
    Banners,
    Cart,
    Twitter,
    Document,
    Checkout,
    Whatsapp,
    Dashboard,
    OpenInNew,
    Payment,
    Group,
    Rating,
    Settings,
    Sitemap,
    Link,
    Notification,
  },
  components: {
    AlertBanner,
    FrillWidget,
    EmailNotVerified,
    AccountDowngraded,
    Breadcrumbs,
    Loader,
    TheNotification,
    SwitchBiz,
    AppLayout,
  },
  computed: {
    ...mapGetters({
      bizUrl: "biz/url",
    }),
  },
  mounted() {
    this.$root.$on("rehydrate", (campaignId, web, serviceWorker) => {
      if (campaignId) {
        setCampaign(campaignId);
      }
      this.rehydrate(web, serviceWorker);
    });
  },
  created() {
    this.startHydration();
  },
  data() {
    return {
      loading: false,
      unreadNotificationCount: 0,
    };
  },
  methods: {
    ...mapActions({
      hydrate: "hydrate",
    }),
    rehydrate(web, serviceWorker = true) {
      /**
       * Need to register prototypes in side created hook to have access to component instance.
       * We're connecting with the Chrome Extension
       * When any change is done in admin panel which needs to be updated in extension too,
       * we sent a rehydrate request to extension service worker script.
       *
       * https://developer.chrome.com/docs/extensions/mv2/messaging/
       */

      //Rehydrate web platform only if required
      if (web) {
        this.$cache.evict({
          id: "ROOT_QUERY",
        });
        this.startHydration(serviceWorker);
      }
    },
    startHydration(serviceWorker) {
      if (!serviceWorker) {
        this.loading = true;
      }
      this.loading = true;

      this.hydrate({
        request: this.$query(
          hydrate,
          {
            campaignId: getCampaign(),
            userId: null,
          },
          {
            // Cache refresh is required here for biz setup create account flow, redirection back to this route
            noCache: true,
          }
        ),
        version,
      })
        .then((res) => {
          if (!res.user.isStoreOnboardingCompleted) {
            this.$router.push({ name: "onboard-questions" });
            return;
          }

          if (!res.campaign.vepaarProducts.stores.length)
            this.$router.push({ name: "get-started" });

          this.updateNotificationCount(res.user.id);
        })
        .catch((err) => {
          console.log("🚀 ~ created ~ err:", err);
          toWeb("logout");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateNotificationCount(userId) {
      this.$query(unreadNotification, {
        userId,
        filters: {
          readAt: false,
          product: "store",
        },
      }).then(({ data }) => {
        this.unreadNotificationCount = data.notifications.pagination.total;
      });
    },
  },
};
</script>
