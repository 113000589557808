<template>
  <div class="vp-flex vp-flex-col vp-h-full vp-justify-center">
    <Loader class="vp-mx-auto" />
    <div v-if="showLogo" class="vp-flex vp-justify-center vp-mt-4">
      <img style="height: 30px" src="/img/logo-dark.png" alt="" />
    </div>
  </div>
</template>

<script>
import Loader from "../loader.vue";

export default {
  components: {
    Loader,
  },
  props: {
    showLogo: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
