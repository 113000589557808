<template>
  <div>
    <SelectGrid
      v-if="currentStepOnboardQuestion.type === 'radiochip'"
      @input="$emit('onSelect', $event)"
      :value="currentFormState.value"
      :options="currentStepOnboardQuestion.fields"
    />

    <VpRadioGroup
      v-if="currentStepOnboardQuestion.type === 'radio'"
      :value="currentFormState.value"
      @input="$emit('onSelect', $event)"
      class="vp-space-y-4"
      stack
      :options="currentStepOnboardQuestion.fields"
    />

    <VpButtonMd
      class="vp-mt-4"
      v-if="currentFormState.value"
      label="Next"
      @click.native="$emit('next')"
    />
  </div>
</template>

<script>
import SelectGrid from "../select-grid.vue";

export default {
  props: {
    currentStepOnboardQuestion: Object,
    currentFormState: Object,
  },

  components: {
    SelectGrid,
  },
};
</script>
